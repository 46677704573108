import { Controller } from "@hotwired/stimulus"
// https://editorjs.io/
import EditorJS from '@editorjs/editorjs';
import BulmaTagsInput from '@creativebulma/bulma-tagsinput';
import axios from 'axios'
import { tools } from '../config/editor_tools'
import bulmaTagsInputConfig from '../config/bulma_tags_input'
// Connects to data-controller="new-articles"
export default class extends Controller {
  static targets = ['title', 'tags', 'attitude', 'publicState']

  connect() {
    window.onbeforeunload = function(){
      // メッセージをカスタム出来るブラウザはなくなっているらしい
      // セットするとpc chromeとかでデフォルトのメッセージが表示されるので
      // 設定している
      // https://qiita.com/ponsuke0531/items/bbcf6f5225137dd3ab5f
      return 'ページを再読み込みしますか？編集中のデータは破棄されます。';
    };

    // https://bulma-tagsinput.netlify.app/get-started/javascript-api/
    let inputTags = this.tagsTarget;
    // Access to the BulmaTagsInput instance
    this.btiInstance = new BulmaTagsInput(inputTags, bulmaTagsInputConfig);

    this.editor = new EditorJS({
      autofocus: true,
      /**
       * Id of Element that should contain the Editor
       */
      holderId : 'editorjs',

      /**
       * Available Tools list.
       * Pass Tool's class or Settings object for each Tool you want to use
       */
      tools: tools,
      /**
       * Previously saved data that should be rendered
       */
      data: {
      }
    });
  }
  
  save() {
    let title = this.titleTarget.value;
    // https://bulma-tagsinput.netlify.app/get-started/javascript-api/
    // なんか items() のようにメソッド呼び出し来なかった
    let tags = this.btiInstance.items;

    let publicState = this.publicStateTarget.value;
    let attitude = this.attitudeTarget.value
    this.editor.save().then((outputData) => {
      this.postArticle(title, tags, attitude, outputData, publicState);
    }).catch((error) => {
      console.log('Saving failed: ', error)
    });
  }
  
  postArticle(title, tags, attitude, content, publicState) {
    window.onbeforeunload = null;
    return axios.post('/api/articles', {
      title: title,
      tags: tags,
      attitude: attitude,
      content: content,
      public_state: publicState
    })
    .then(function (response) {
      const redirectTo = response.data.location;
      window.location.href = redirectTo;
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  
  // blurTags() {
    // // 確定していない入力中のタグを設定する
    // this.btiInstance.add(
    //   this.btiInstance.input.value
    // )
    // this.btiInstance.input.value = null;
  // }
}
