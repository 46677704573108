import { Controller } from "@hotwired/stimulus"
import BulmaTagsInput from '@creativebulma/bulma-tagsinput';
import bulmaTagsInputConfig from '../config/bulma_tags_input'

// Connects to data-controller="search-form"
export default class extends Controller {
  static targets = ['tags']

  connect() {
    let inputTags = this.tagsTarget;
    BulmaTagsInput.attach(inputTags, bulmaTagsInputConfig);
  }
}
