let config = {
  selectable: false,
  source: async function(value) {
    // Value equal input value
    // We can then use it to request data from external API
    return await fetch(`/api/tags?name=${encodeURIComponent(value)}`)
      .then(function(response) {
        return response.json();
      });
  }
};
export default config;
