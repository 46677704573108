import { Controller } from "@hotwired/stimulus"
// https://editorjs.io/
import EditorJS from '@editorjs/editorjs';
import axios from 'axios'
import { tools } from '../config/editor_tools'

// Connects to data-controller="show-articles"
export default class extends Controller {

  connect() {
    const self = this;
    axios.get(`/api${location.pathname}`, {
      params: {
      }
    })
    .then(function (response) {
      const data = response.data;
      self.createEditorJS(data.content);
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    }); 
  }
  
  createEditorJS(data) {
    const editor = new EditorJS({
      readOnly: true,
      
      /**
       * Id of Element that should contain the Editor
       */
      holderId : 'show_editorjs',

      /**
       * Available Tools list.
       * Pass Tool's class or Settings object for each Tool you want to use
       */
      tools: tools,

      /**
       * Previously saved data that should be rendered
       */
      data: data
    });
  }
}
