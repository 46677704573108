import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

// Connects to data-controller="follow-relations"
export default class extends Controller {
  static targets = ['button']
  static values = {
    visibleName: String,
    followed: Boolean
  }

  connect() {
  }

  followedValueChanged() {
    const button = this.buttonTarget;
    if (this.followedValue) {
      button.classList.add('is-danger');
      button.classList.add('is-outlined');
      button.innerText = 'フォローを解除';
    } else {
      button.classList.remove('is-danger');
      button.classList.remove('is-outlined');
      button.innerText = 'フォローする';
    }
  }

  toggle(event) {
    return axios({
      method: 'put',
      url: `/api/follow_relations/${this.visibleNameValue}`
    })
    .then((response) => {
      this.followedValue = response.data.followed;
    })
    .catch((error) => {
      // console.log(error);
    });
  }
}
