import { Controller } from "@hotwired/stimulus"
import axios from 'axios'
import * as changeCase from "change-case";

// Connects to data-controller="bookmark"
export default class extends Controller {
  static targets = ['icon']
  static values = {
    bookmarked: Boolean,
    articleUuid: String
  }
  connect() {
    // console.log("this.bookmarkedValue:", this.bookmarkedValue)
  }
  
  bookmarkedValueChanged() {
    const icon = this.iconTarget;
    
    if (this.bookmarkedValue) {
      icon.classList.replace('mdi-star-outline', 'mdi-star');
    } else {
      icon.classList.replace('mdi-star', 'mdi-star-outline');
    }
  }
  
  toggle(event) {
    let method;
    if (this.bookmarkedValue) {
      method = 'delete';
    } else {
      method = 'post';
    }

    let self = this;
    return axios({
      method: method,
      url: '/api/bookmarks',
      data: {
        [changeCase.snakeCase('articleUuid')]: this.articleUuidValue,
      }
    })
    .then(function (response) {
      self.bookmarkedValue = response.data.bookmarked;
    })
    .catch(function (error) {
      // console.log(error);
    });
  }
}
