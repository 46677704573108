export { tools };

import Header from '@editorjs/header';
import NestedList from '@editorjs/nested-list';
import Embed from '@editorjs/embed';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import CodeTool from '@editorjs/code';
import ImageTool from '@editorjs/image';
// TODO: endpoint
// import LinkTool from '@editorjs/link';
import Delimiter from '@editorjs/delimiter';
// import Warning from '@editorjs/warning';
import Table from '@editorjs/table';

const tools = {
  header: {
    class: Header,
    inlineToolbar : true,
    shortcut: 'CMD+SHIFT+H',
  },
  list: {
    class: NestedList,
    inlineToolbar: true,
    shortcut: 'CMD+SHIFT+L',
  },
  embed: {
    class: Embed//,
    // config: {
    //   // services: {
    //   //   youtube: true,
    //   //   coub: true
    //   // }
    // }
  },
  quote: {
    class: Quote,
    inlineToolbar: true,
    shortcut: 'CMD+SHIFT+O',
    // config: {
    //   quotePlaceholder: 'Enter a quote',
    //   captionPlaceholder: 'Quote\'s author',
    // },
  },
  marker: {
    class: Marker,
    shortcut: 'CMD+SHIFT+M',
  },
  code: CodeTool,
  // linkTool: {
  //   class: LinkTool,
  //   config: {
  //     endpoint: 'http://localhost:8008/fetchUrl', // Your backend endpoint for url data fetching
  //   }
  // }
  delimiter: Delimiter,
  table: {
    class: Table,
  },
  image: {
    class: ImageTool,
    shortcut: 'CMD+SHIFT+I',
    config: {
      endpoints: {
        byFile: '/api/content_attachments', // Your backend file uploader endpoint
        byUrl: '/api/content_attachments/hoge', // Your endpoint that provides uploading by Url
      }
    }
  }
};