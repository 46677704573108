import { Controller } from "@hotwired/stimulus"
import axios from 'axios'
import * as changeCase from "change-case";

// Connects to data-controller="following-article-tags"
export default class extends Controller {
  static targets = ['button', 'notification', 'notificationClose', 'notificationContent']
  static values = {
    tagName: String,
    followed: Boolean
  }
  connect() {
  }
  
  followedValueChanged() {
    const button = this.buttonTarget;
    if (this.followedValue) {
      button.classList.add('is-danger');
      button.classList.add('is-outlined');
      button.innerText = 'フォローを解除';
    } else {
      button.classList.remove('is-danger');
      button.classList.remove('is-outlined');
      button.innerText = 'フォローする';
    }
  }
  toggle(event) {
    return axios.post(`/api/following_article_tags/toggle`, {
      [changeCase.snakeCase('tagName')]: this.tagNameValue
    })
    .then((response) => {
      this.followedValue = response.data.followed;
    })
    .catch((error) => {
      this.notificationTarget.classList.remove('is-hidden');
      this.notificationContentTarget.innerHTML = `
        サーバーのトラブルにより正常に処理を完了出来ませんでした。
        <br>
        時間を空けて再度やり直してみてください。`;
    });
  }
  
  notificationClose(){
    this.notificationTarget.classList.add('is-hidden');
  }
}
